<template>
    <div>
        <v-dialog
            v-if="visible"
            v-model="visible"
            transition="dialog-top-transition"
            scrollable
            persistent
            max-width="700"
        >
            <v-card class="modal-maincard">
                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card 
                        flat
                    >
                        <v-card-text>
                            <v-form ref="form" lazy-validation>
                                <!--поле от Кого-->
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="3">
                                        <label class="f-label">{{$t("От_лица_кого")}}</label>
                                    </v-col>
                                    <v-col  cols="12" sm="12" md="9">
                                        <div class="icon-click-row-group icrg-align-center">                                                    
                                            <div @click="onFromSelect">
                                                <v-icon small v-tooltip.left-center="selectFromTooltip">
                                                    fas fa-edit
                                                </v-icon>
                                            </div>
                                            <div class="onlyReadData">
                                                <v-workplace-chip v-if="from" :id="from.workplaceId" :name="from.name" />
                                                <div class="chipAreaNoData" v-else></div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="3">
                                        <label class="f-label">{{$t("С_текстом")}}</label>
                                    </v-col>
                                    <v-col  cols="12" sm="12" md="9">
                                        <div class="icon-click-row-group">
                                            <div @click="onSelectResolutionText">
                                                <v-icon small v-tooltip.left-center="selectTextTooltip">
                                                    fas fa-edit
                                                </v-icon>
                                            </div>
                                            <div class="autocomplete">
                                                <v-textarea
                                                    v-model="text"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    @focus="focus" 
                                                    @keydown.13="chooseItem" 
                                                    @keydown.tab="chooseItem" 
                                                    @keydown.40="moveDown" 
                                                    @keydown.38="moveUp"
                                                    @click="clickTA(), getCaretPosPX(id)" 
                                                    :id="id"
                                                >
                                                </v-textarea>
                                                <ul :class="{
                                                    'autocomplete-list': true,
                                                    'a-l-in-modal': true,
                                                    [id+'-list']: true
                                                }" v-show="searchMatch.length > 0 && clickedChooseItem">
                                                    <li 
                                                        v-for="(result, index) in searchMatch" 
                                                        :key="index"
                                                        :class="{active: selectedIndex === index}" 
                                                        @click="selectItem(index)" 
                                                        v-html="highlightWord(result)"
                                                    >
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="3">
                                        <label class="f-label">{{ $t('Тип_контроля') }}</label>
                                    </v-col>
                                    <v-col  cols="12" sm="12" md="9">
                                        <div class="onlyReadData">{{ $t(document.Card.Periodicity==0 ? GetControlTypes[document.Card.ControlType] : 'Периодический_контроль') }}</div>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="3">
                                        <label class="f-label">{{ $t('Контрольный_срок') }}</label>
                                    </v-col>
                                    <v-col  v-if="document.Card.Periodicity>0" cols="12" sm="12" md="9">
                                        <div class="onlyReadData">{{ formatDate(innerLimit) }}</div>
                                    </v-col>
                                    <v-col v-else cols="12" sm="12" md="9">
                                        <v-menu 
                                            v-model="controleDateMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="innerLimit | formattedDate"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on" 
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    class="datepick-input"
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </template>

                                            <v-date-picker
                                                v-model="innerLimit"
                                                @input="controleDateMenu = false"
                                                color="teal"
                                                :max="controlDateRange.Max"
                                                :min="controlDateRange.Min"
                                                :first-day-of-week="1"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>

                                <v-row v-if="isControlTypeControl" no-gutters>
                                    <v-col cols="12" sm="12" md="3">
                                        <label class="f-label">{{ $t('Ответственный') }}</label>
                                    </v-col>
                                    <v-col  cols="12" sm="12" md="9">
                                        <div class="icon-click-row-group icrg-align-center"> 
                                            <div
                                                @click="onExecuterSelect"
                                            >
                                                <v-icon 
                                                    small 
                                                    v-tooltip.left-center="selectExecuterTooltip"
                                                >
                                                    fas fa-edit
                                                </v-icon>
                                            </div>
                                            <draggable
                                                v-model="svodExecuters"
                                                :class="['more-per-lab-wrap', 'onlyReadData', dragInProcess ? 'isDragClass' : '']"
                                                :group="{ name: 'executers', pull: false }"
                                                draggable=".item-drag:not(.disable-drag):not(.disable-drag-wo)"
                                            >
                                                <div class="chipAreaNoData" v-if="svodExecuters.length == 0 && executers.length == 0 && isControlTypeControl"></div>
                                                <template v-else v-for="executer in svodExecuters">
                                                    <v-enterprise-chip
                                                        v-if="executer.type == 0"
                                                        :key="executer.enterprise"
                                                        :name="executer.name"
                                                        :main="true"
                                                        :class="['item-drag', 'disable-drag-wo']"
                                                    />
                                                    <v-workplace-chip
                                                        v-else
                                                        :key="executer.workplaceId"
                                                        :id="executer.workplaceId"
                                                        :name="executer.name"
                                                        :main="true"
                                                        :class="['item-drag', 'disable-drag-wo']"
                                                    />
                                                </template>
                                            </draggable>
                                        </div>
                                    </v-col>     
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="3">
                                        <label class="f-label">{{ $t(isControlTypeControl ? 'Соисполнители' : 'Исполнители') }}</label>
                                    </v-col>
                                    <v-col  cols="12" sm="12" md="9">
                                        <div class="icon-click-row-group icrg-align-center"> 
                                            <div
                                                @click="onExecuterSelect"
                                            >
                                                <v-icon 
                                                    small 
                                                    v-tooltip.left-center="selectExecuterTooltip"
                                                >
                                                    fas fa-edit
                                                </v-icon>
                                            </div>
                                            <draggable
                                                v-model="executers"
                                                :class="['more-per-lab-wrap', 'onlyReadData']"
                                                :sort="false"
                                                :group="{ name: 'executers' }"
                                                @start="onDragStart"
                                                @end="onDragEnd"
                                                draggable=".item-drag:not(.disable-drag)"
                                            >
                                                <div class="chipAreaNoData" v-if="executers.length == 0 && !isControlTypeControl"></div>
                                                <template v-else v-for="executer in executers">
                                                    <v-enterprise-chip
                                                        v-if="executer.type == 0"
                                                        :key="executer.enterprise"
                                                        :name="executer.name"
                                                        :class="['item-drag']"
                                                    />
                                                    <v-workplace-chip
                                                        v-else
                                                        :key="executer.workplaceId"
                                                        :id="executer.workplaceId"
                                                        :name="executer.name"
                                                        :class="['item-drag']"
                                                    />
                                                </template>

                                            </draggable>
                                        </div>                                            
                                    </v-col>
                                </v-row>
                            </v-form>        
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="cyan"
                        text
                        depressed
                        @click="ok"
                        v-if="isValid && svodExecuters.length !== 0 && from"
                    >
                        {{ $t("Ок") }}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>

        </v-dialog>

        <SelectResolutionTextDlg ref="SelectResolutionTextDlgRef" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectResolutionTextDlg from '@/components/dialogs/SelectResolutionTextDlg';
import i18n from '@/i18n'
import draggable from 'vuedraggable'
import sys from '@/services/system';
import _ from 'lodash';
//import SelectResolutionTextDlg from '@/components/dialogs/SelectResolutionTextDlg';

export default {
    name: "SendForExecutionDlg",
     components: {
        draggable,
        SelectResolutionTextDlg,
    },   
    data: () => ({
        title: "Отправка_на_исполнение",
        options: {
            color: 'grey lighten-3',
            width: 400,
            zIndex: 200,
            noconfirm: false,
        },

        //for autocomplete
        listResolutionsText: [],
        id: 'input-' + parseInt(Math.random() * 1000),
        searchMatch: [],
        selectedIndex: 0,
        clickedChooseItem: false,
        wordIndex: 0,
        //for autocomplete end

        controleDateMenu: false,
        dragInProcess: false,
        isValid: false,
    }),
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters('dialogs/sendForExecution', { visible: 'isVisible', valid: 'isValid', }),
        ...mapGetters('references', ['GetReference', 'GetControlTypes', 'getTimestamp']),
        ...mapGetters('actionsource', { dataSource: 'getDataSource', isDataSourceCommon: 'isDataSourceCommon' }),
        ...mapGetters('auth', { isCommonServiceEnabled: 'isCommonServiceEnabled' }),
        ...mapGetters({ requiredRule: 'getRequiredRule' }),        
        document() {
            return this.dataSource?.Data?.Object?.Document;
        },
        executers: {
            get: function() {
                let executers = this.$store.getters['dialogs/sendForExecution/getExecuters'];
                return this.isControlTypeControl ? _.tail(executers) : executers;
            },
            set: function() {
                return;
            }
        },
        svodExecuters: {
            get: function() {
                let executers = this.$store.getters['dialogs/sendForExecution/getExecuters'];
                return executers?.length > 0 ? [ executers[0] ] : [];
            },
            set: function(v) {
                let originalExecuters = Array.from(this.$store.getters['dialogs/sendForExecution/getExecuters']);
                let executers = Array.from(originalExecuters);
                let oldMainExecuter = executers[0];
                let indexOfNewMainExecuterInSetterValue = 1 - v.indexOf(oldMainExecuter);
                let newMainExecuter = v[indexOfNewMainExecuterInSetterValue];
                let index = executers.indexOf(newMainExecuter);
                executers.splice(index, 1, oldMainExecuter);
                executers.splice(0, 1, newMainExecuter);
                this.$store.commit('dialogs/sendForExecution/SET_EXECUTERS', executers);
            }
        },
        from: {
            get: function() {
                return this.$store.getters['dialogs/sendForExecution/getFrom'];
            },
            set: function(v) {
                this.$store.commit('dialogs/sendForExecution/SET_FROM', v);
            }
        },
        text: {
            get: function() {
                return this.$store.getters['dialogs/sendForExecution/getText'];
            },
            set: function(v) {
                this.$store.commit('dialogs/sendForExecution/SET_TEXT', v);
            }
        },
        innerLimit: {
            get: function() {
                return this.$store.getters['dialogs/sendForExecution/getControlDate'];
            },
            set: function(v) {
                this.$store.commit('dialogs/sendForExecution/SET_CONTROLDATE', v);
            }
        },
        //#region for autocomplete
        listToSearch() {
			return this.listResolutionsText;
		},
		currentWord() {
			return this.text.replace(/(\r\n|\n|\r)/gmi, ' ').split(' ')[this.wordIndex];
		},
		inputSplitted() {
			return this.text.replace(/(\r\n|\n|\r)/gmi, ' ').split(' ');
		},
        taskResolutionText(){
            return this.text;
        },
        //#endregion
        selectFromTooltip(){
            return i18n.t("От_лица_кого_дается_поручение");
        },
        selectTextTooltip() {
            return this.$t("Выбрать_текст");
        },
        selectExecuterTooltip() {
            return this.$t("Выбрать_исполнителей");
        },
        isControlTypeControl() {
            return ![3,4].includes(this.document?.Card?.ControlType);
        },
        controlDateRange() {

            let Max = '';
            let Min = '';
            let max = this.$moment(this.document?.Card?.ControlDate).startOf('day');
            let min = this.$moment(this.getTimestamp).startOf('day');

            if (max && max.isValid()) {
                Max = max.format('YYYY-MM-DD');

                if (min && min.isValid() && min.isBefore(max))                           
                    Min = min.format('YYYY-MM-DD');       
            }
            else if (min && min.isValid()) {
                Min = min.format('YYYY-MM-DD');
            }
            return { Max, Min };
        },
    },
    methods: {
        ...mapActions('references', ['getReference']),
        ...mapActions('dialogs/sendForExecution', ['ok', 'cancel']),
        formatDate (source) {
            return this.$moment(source).format('DD.MM.YYYY');
        },
        async onFromSelect (){
            try
            {
                let implicitExclude = this.$store.getters['dialogs/sendForExecution/getExecuters'].map(x => x.type == 0 ? x.enterprise : x.workplaceId);

                let selectMemberParams = { 
                    title: i18n.t("Выбор_инициатора"),
                    includeInner: true,
                    applyresolutions: true,
                    multiple: false,
                    selected: this.from ? [ this.from.workplaceId ] : [],
                    implicitExclude
                }

                this.from = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);                
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        async onSelectResolutionText() {
            try
            {
                let resolutionText = await this.$refs.SelectResolutionTextDlgRef.open({ title: 'Справочник_текста_резолюций' });
                this.clickedChooseItem = false;
                this.searchMatch = [];
                this.text = resolutionText + ' ';
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        onDragStart() {
            this.dragInProcess = true;
        },
        onDragEnd() {
            this.dragInProcess = false;
        },
        //#region for autocomplete 
        highlightWord(word) {
			return String(word).replace(new RegExp(this.currentWord, 'gi'), match => {
                return `<span style="background:#C5E1A5">${match}</span>`;
            });
		},
		setWord(word) {
            let currentWords = this.text.replace(/(\r\n|\n|\r)/gm, '__br__ ').split(' ');
            
            if(!currentWords[this.wordIndex]) return;

			currentWords[this.wordIndex] = currentWords[this.wordIndex].replace(this.currentWord, word + ' ');
            this.text = currentWords.join(' ').replace(/__br__\s/g, '\n');
        },
		moveDown(e) {
            e.preventDefault();
			if (this.selectedIndex < this.searchMatch.length - 1) {
				this.selectedIndex++;
                this.scrollListMenu(this.selectedIndex * 40);
			}
		},
		moveUp(e) {
            e.preventDefault();
			if (this.selectedIndex > 0) {
				this.selectedIndex--;
                this.scrollListMenu(this.selectedIndex * 40);
            }
		},
        scrollListMenu(offset){
            const root = document.querySelector("." + this.id + "-list");
            if(root){
                root.scrollTo({
                    top: offset,
                    behavior: 'smooth',
                });
            }  
        },
		selectItem(index) {
			this.selectedIndex = index;
			this.chooseItem();
		},
		chooseItem(e) {
			
			if (this.searchMatch.length > 0) {
				if (e) {
					e.preventDefault();
				}
				this.setWord(this.searchMatch[this.selectedIndex]);
				this.selectedIndex = -1;
                this.scrollListMenu(0);
			}
		},
		focus() {
			this.searchMatch = [];
			if (this.currentWord !== "" && this.currentWord?.length > 2) {
				this.searchMatch = this.listToSearch?.filter(x => sys.checkSearch(x,this.currentWord)) ?? [];
			}
			if (
				this.searchMatch.length === 1 &&
				this.currentWord === this.searchMatch[0]
			) {
				this.searchMatch = [];
			}
		},
        clickTA(){
            this.clickedChooseItem = true;
        },
        //caret position
        getCaretPosPX(objName) {
            let elementTA = document.querySelector('#' + objName);
            let element = document.querySelectorAll('.' + objName + '-list');
            let coordinates = this.getCaretCoordinates(elementTA, elementTA.selectionEnd);
            let topCoordinates = coordinates.top - elementTA.scrollTop;
            
            if (element[0]) {
                element[0].style.top = topCoordinates + 20 + 'px';
                //element[0].style.left = coordinates.left + 20 + 'px';
            }
            //делаем возможным получение индекса слова 
            this.cropStrToCaret(elementTA);
            
        },
        cropStrToCaret(elementTA){
            setTimeout(() => {
                let strLengthToCaret = elementTA.selectionStart;
                let strTextToCaret = this.text.slice(0, strLengthToCaret);
                let arrTextToCaret = strTextToCaret.replace(/(\r\n|\n|\r)/gmi, ' ').split(' ');
                this.wordIndex = arrTextToCaret.length - 1;
                this.focus();
            }, 100);

        },
        getCaretCoordinates(element, position) {
            // The properties that we copy into a mirrored div.
            // Note that some browsers, such as Firefox,
            // do not concatenate properties, i.e. padding-top, bottom etc. -> padding,
            // so we have to do every single property specifically.
            var properties = [
            'boxSizing',
            'width',  // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
            'height',
            'overflowX',
            'overflowY',  // copy the scrollbar for IE

            'borderTopWidth',
            'borderRightWidth',
            'borderBottomWidth',
            'borderLeftWidth',

            'paddingTop',
            'paddingRight',
            'paddingBottom',
            'paddingLeft',

            // https://developer.mozilla.org/en-US/docs/Web/CSS/font
            'fontStyle',
            'fontVariant',
            'fontWeight',
            'fontStretch',
            'fontSize',
            'lineHeight',
            'fontFamily',

            'textAlign',
            'textTransform',
            'textIndent',
            'textDecoration',  // might not make a difference, but better be safe

            'letterSpacing',
            'wordSpacing'
            ];

            var isFirefox = !(window.mozInnerScreenX == null);
            //var mirrorDivDisplayCheckbox = document.getElementById('mirrorDivDisplay');
            var mirrorDiv, computed, style;
            // mirrored div
            mirrorDiv = document.getElementById(element.nodeName + '--mirror-div');
            if (!mirrorDiv) {
                mirrorDiv = document.createElement('div');
                mirrorDiv.id = element.nodeName + '--mirror-div';
                document.body.appendChild(mirrorDiv);
            }

            style = mirrorDiv.style;
            computed = getComputedStyle(element);

            // default textarea styles
            style.whiteSpace = 'pre-wrap';
            if (element.nodeName !== 'INPUT')
                style.wordWrap = 'break-word';  // only for textarea-s

            // position off-screen
            style.position = 'absolute';  // required to return coordinates properly
            style.top = element.offsetTop + parseInt(computed.borderTopWidth) + 'px';
            style.left = "400px";
            //style.visibility = mirrorDivDisplayCheckbox.checked ? 'visible' : 'hidden';  // not 'display: none' because we want rendering

            // transfer the element's properties to the div
            properties.forEach(function (prop) {
                style[prop] = computed[prop];
            });

            if (isFirefox) {
                style.width = parseInt(computed.width) - 2 + 'px'  // Firefox adds 2 pixels to the padding - https://bugzilla.mozilla.org/show_bug.cgi?id=753662
                // Firefox lies about the overflow property for textareas: https://bugzilla.mozilla.org/show_bug.cgi?id=984275
                if (element.scrollHeight > parseInt(computed.height))
                style.overflowY = 'scroll';
            } else {
                style.overflow = 'hidden';  // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'
            }  

            mirrorDiv.textContent = element.value.substring(0, position);
            // the second special handling for input type="text" vs textarea: spaces need to be replaced with non-breaking spaces - http://stackoverflow.com/a/13402035/1269037
            if (element.nodeName === 'INPUT')
                mirrorDiv.textContent = mirrorDiv.textContent.replace(/\s/g, "\u00a0");

            var span = document.createElement('span');
            // Wrapping must be replicated *exactly*, including when a long word gets
            // onto the next line, with whitespace at the end of the line before (#7).
            // The  *only* reliable way to do that is to copy the *entire* rest of the
            // textarea's content into the <span> created at the caret position.
            // for inputs, just '.' would be enough, but why bother?
            span.textContent = element.value.substring(position) || '.';  // || because a completely empty faux span doesn't render at all
            span.style.backgroundColor = "lightgrey";
            mirrorDiv.appendChild(span);

            var coordinates = {
                top: span.offsetTop + parseInt(computed['borderTopWidth']),
                left: span.offsetLeft + parseInt(computed['borderLeftWidth'])
            };
            mirrorDiv.remove();
            return coordinates;
        },
        //#endregion
        async onExecuterSelect() {
            try
            {
                let implicitExclude = []; // неявно исключить (нет в выбранных, недоступен для выбора)

                if (this.from)
                    implicitExclude.push(this.from.workplaceId);

                let selectMemberParams =  {
                    title: this.$t("Выбор_исполнителей"),
                    includeInner: true,
                    includeOuter: this.isCommonServiceEnabled,
                    //includeOuterEnterprises: !this.isDataSourceCommon,
                    includeExecutersGroups: true,
                    multiple: true,
                    selected: this.$store.getters['dialogs/sendForExecution/getExecuters'].map(x => x.type == 0 ? x.enterprise : x.workplaceId),
                    implicitExclude
                }

                let result = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);
                this.$store.commit('dialogs/sendForExecution/SET_EXECUTERS', result);
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        validateFormMethod(){
            this.isValid = this.$refs?.form?.validate();  
        }
    },
    watch: {
        //for autocomplete
        taskResolutionText: function (val) {
            if (!val) return;

            this.selectedIndex = 0;

			if (this.clickedChooseItem)
                this.getCaretPosPX(this.id);
        }
    },
    async created() {
        (async() => {
            this.listResolutionsText = (await this.getReference({ id: 1017 })).map(item => item.Value);
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.validateFormMethod();
        })();
    },
    updated () {
        this.validateFormMethod();
    }
}
</script>